import { ComponentProps } from 'react'
import { cva } from 'class-variance-authority'
import { Switch } from '@/components/ui/switch'

type ViverseSwitchProps = ComponentProps<typeof Switch> & {
  variant?: 'default'
  thumbClassName?: string
}

const switchVariants = cva('', {
  variants: {
    variant: {
      default: 'data-[state=unchecked]:bg-vive-primary-70 [&>span]:bg-vive-primary-20',
    },
  },
})

function ViverseSwitch({ variant = 'default', ...props }: ViverseSwitchProps) {
  return <Switch {...props} className={switchVariants({ variant })} />
}

export default ViverseSwitch
