import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { ComponentProps, PropsWithChildren } from 'react'

interface DropdownSelectProps extends PropsWithChildren<ComponentProps<typeof Select>> {}

function DropdownSelect(props: DropdownSelectProps) {
  return <Select {...props} />
}

interface DropdownSelectTriggerProps
  extends PropsWithChildren<ComponentProps<typeof SelectTrigger>> {}

function DropdownSelectTrigger({ children, ...props }: DropdownSelectTriggerProps) {
  return (
    <SelectTrigger
      className="h-14 border-2 border-vive-primary-70 bg-vive-popup transition-all hover:border-vive-brand-50 md:min-w-full"
      {...props}
    >
      {children}
    </SelectTrigger>
  )
}

interface DropdownSelectValueProps extends PropsWithChildren<ComponentProps<typeof SelectValue>> {
  title: string
  description: string
}

function DropdownSelectValue({ title, description, children, ...props }: DropdownSelectValueProps) {
  return (
    <SelectValue asChild {...props}>
      <div className="flex h-14 flex-col justify-center text-left text-white">
        <div>{title}</div>
        <div className="text-vive-primary-40">{description}</div>
      </div>
    </SelectValue>
  )
}

interface DropdownSelectContentProps
  extends PropsWithChildren<ComponentProps<typeof SelectContent>> {}

function DropdownSelectContent({ children, className, ...props }: DropdownSelectContentProps) {
  return (
    <SelectContent
      className={cn('translate-x-[-2px] border-2 border-vive-primary-70 bg-vive-popup', className)}
      {...props}
    >
      {children}
    </SelectContent>
  )
}

export interface DropdownSelectItemProps
  extends PropsWithChildren<ComponentProps<typeof SelectItem>> {
  value: string
  title: string
  description: string
  selected?: boolean
}

function DropdownSelectItem({
  title,
  description,
  className,
  selected,
  ...props
}: DropdownSelectItemProps) {
  return (
    <SelectItem
      className={cn(
        'w-full cursor-pointer focus:bg-transparent focus:hover:bg-vive-primary-70',
        className,
        {
          '!bg-vive-primary-70': selected,
        },
      )}
      {...props}
    >
      <div className="flex h-14 flex-col justify-center text-left text-white">
        <div>{title}</div>
        <div className="text-vive-primary-40">{description}</div>
      </div>
    </SelectItem>
  )
}

DropdownSelect.Trigger = DropdownSelectTrigger
DropdownSelect.Value = DropdownSelectValue
DropdownSelect.Content = DropdownSelectContent
DropdownSelect.Item = DropdownSelectItem

export default DropdownSelect
