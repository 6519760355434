import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { cn } from '@/lib/utils'
import { Input, InputProps } from '@/components/ui/input'

type ViverseInputProps = InputProps & {}

function ViverseInput({ className, onChange, maxLength, ...props }: ViverseInputProps) {
  const [valueLength, setValueLengh] = useState(0)
  const defaultValue = useRef(typeof props.value === 'string' ? props.value : '')

  useEffect(() => {
    if (defaultValue.current) {
      setValueLengh(defaultValue.current.length)
    }
  }, [])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(e)
    setValueLengh(e.target.value.length)
  }

  return (
    <div
      className={cn(
        'box-border flex items-center rounded-md border-2 border-vive-primary-70 bg-vive-popup transition-all duration-300 focus-within:!border-vive-border-focus hover:border-vive-brand-50',
        className,
      )}
    >
      <Input
        placeholder="Email"
        className="min-h-12 border-none bg-vive-popup text-white"
        onChange={handleChange}
        maxLength={maxLength}
        {...props}
      />
      {maxLength && (
        <div className="h-full w-max text-nowrap px-3">
          <span className="caption">
            {valueLength} / {maxLength}
          </span>
        </div>
      )}
    </div>
  )
}

export default ViverseInput
