import { PropsWithChildren, ReactNode } from 'react'
import ViverseDialog from '@/components/ViverseDialog'

type CreateWorldDialogProps = PropsWithChildren<{
  dialogTrigger: ReactNode
  title: string
  isDialogOpen?: boolean
  handleClose?: () => void
  handleOpenChange?: (open: boolean) => void
}>

function CreateWorldDialog({
  children,
  title,
  dialogTrigger,
  isDialogOpen,
  handleClose,
  handleOpenChange,
}: CreateWorldDialogProps) {
  return (
    <ViverseDialog
      contentClassName="flex h-full flex-col md:max-h-[calc(100dvh-6rem)] w-full xl:max-w-[940px] 2xl:max-h-[802px]"
      triggerClassName="max-lg:w-[248px] lg:contents"
      dialogTrigger={dialogTrigger}
      title={title}
      open={isDialogOpen}
      onClose={handleClose}
      onOpenChange={handleOpenChange}
    >
      {children}
    </ViverseDialog>
  )
}

export default CreateWorldDialog
