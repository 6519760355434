import { InputOTP, InputOTPGroup, InputOTPSlot } from '@/components/ui/input-otp'
import { useTranslations } from 'next-intl'
import { ComponentProps } from 'react'
import { FormMessage } from '@/components/ui/form'

type ViverseInputOTPProps = Omit<ComponentProps<typeof InputOTP>, 'children' | 'ref' | 'render'> & {
  hasError?: boolean
}

function ViverseInputOTP({ maxLength, hasError, ...props }: ViverseInputOTPProps) {
  const t = useTranslations()

  return (
    <div className="flex flex-col gap-4">
      <InputOTP
        {...props}
        maxLength={maxLength}
        // allow only all digits or all asterisks
        pattern="^(\d+|\*+)$"
        containerClassName="w-max"
      >
        <InputOTPGroup className="gap-2">
          {Array.from({ length: maxLength }, (_, index) => (
            <InputOTPSlot
              key={index}
              index={index}
              className="border-b-2 border-vive-primary-80 text-white ring-vive-border-focus"
              caretClassName="bg-white"
            />
          ))}
        </InputOTPGroup>
      </InputOTP>
      {!hasError ? (
        <div className="body-4">
          {t('profile-page.my-worlds-card-settings-passcode-caption', {
            count: maxLength,
          })}
        </div>
      ) : (
        <FormMessage className="leading-[1.9]" />
      )}
    </div>
  )
}

export default ViverseInputOTP
