'use client'

import { useState, ReactNode } from 'react'
import { useTranslations } from 'next-intl'
import ViverseButton from '@/components/ViverseButton'
import ViverseDialog from '@/components/ViverseDialog'

interface ConfirmDialogProps {
  onConfirm: () => void
  dialogTrigger: ReactNode
  title: string
  content: string | ReactNode
}

function ConfirmDialog({ onConfirm, dialogTrigger, title, content }: ConfirmDialogProps) {
  const t = useTranslations()
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <ViverseDialog
      dialogTrigger={dialogTrigger}
      title={title}
      contentClassName="flex flex-col max-w-[420px] !min-w-0 p-[40px]"
      titleClassName="py-0 header-4 !h-auto !border-b-0"
      withCloseButton={false}
      open={open}
      onClose={handleClose}
      onOpenChange={(open) => setOpen(open)}
    >
      <div className="body-1 mb-8">{content}</div>
      <div className="flex w-full flex-col">
        <ViverseButton variant="colorful" onClick={onConfirm} className="px-10" size="xLarge">
          {t('confirm-dialog.btn.ok')}
        </ViverseButton>
        <ViverseButton className="mt-4 px-10" variant="outline" size="xLarge" onClick={handleClose}>
          {t('confirm-dialog.btn.cancel')}
        </ViverseButton>
      </div>
    </ViverseDialog>
  )
}

export default ConfirmDialog
