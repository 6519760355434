import { z } from 'zod'
import { useTranslations } from 'next-intl'

export const MAX_TITLE_LENGTH = 30

export const useWorldSettingsFormSchema = () => {
  const t = useTranslations()

  const worldSettingsFormSchema = z
    .object({
      title: z
        .string()
        .trim()
        .min(1, { message: t('profile-page.my-worlds-card-settings-validate-title') })
        .max(MAX_TITLE_LENGTH),
      genre: z
        .array(z.string())
        .min(1, { message: t('profile-page.my-worlds-card-settings-validate-genre') }),
      policy: z.union([z.literal('public'), z.literal('private'), z.literal('unlisted')]),
      passcode: z.string(),
      enablePasscode: z.boolean().default(false),
      description: z.string().optional(),
    })
    .superRefine(({ enablePasscode, passcode }, ctx) => {
      if (enablePasscode && !/^[0-9*]{6}$/.test(passcode)) {
        ctx.addIssue({
          code: 'custom',
          message: t('profile-page.my-worlds-card-settings-validate-passcode'),
          path: ['passcode'],
        })
      }
    })

  return worldSettingsFormSchema
}

export type WorldSettingsFormValues = z.infer<ReturnType<typeof useWorldSettingsFormSchema>>
