import { useTranslations } from 'next-intl'
import { useConfigsContext } from '@/context/ConfigsContext'
import { MAX_TITLE_LENGTH } from '@/hooks/useWorldSettingsFormSchema'

export const restrictTitle = (value: string, subLength: number) => {
  const maxLength = MAX_TITLE_LENGTH - subLength
  return value.length > maxLength ? value.slice(0, maxLength) : value
}

export const useCreateWorldSettingsDefaultRoomTitle = () => {
  const { store } = useConfigsContext()
  const t = useTranslations()

  const displayName = store?.profile?.displayName
  const titlePosfix = t('profile-page.my-worlds-card-settings-default-title', {
    username: '',
  })

  return t('profile-page.my-worlds-card-settings-default-title', {
    username: restrictTitle(displayName || '', titlePosfix.length),
  })
}
